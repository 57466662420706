import React from "react"
import CanvasNav from "../canvas/CanvasNav"
import Footer from "./Footer"


const Layout = ({children}) => {
  
 	return (
	  <>
		  <CanvasNav/>
		  {children}
	</>
	);
};
  
export default Layout;
