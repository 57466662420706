import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import ProcessCard from "../../components/cards/ProcessCard"

import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"


import { useState } from "react"



export default function Pages( {data}) {


return (
  <>
  <Layout>
  <Container>
  {isLoggedIn() ? (

 <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-24 space-y-24 lg:space-y-0">
 
  <div className="w-96">
    <Type type="h1">{data.airtable.data.Name}</Type>
  </div>
 </div>
 
    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </Container>
  </Layout>
    </>

  )
}

export const Head = () => <title>Framework</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Docs" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
      }
    }
  }
`
