import React from "react"
import { Link, navigate } from "gatsby"

import Type from "../../components/typography/Type"
import { getUser, isLoggedIn, logout } from "../../services/auth"



	const navigation = [

  { name: 'Journeys', href: '/journeys' },
]




export default function MainNav() {
	

	
	let greetingMessage = ""
	  if (isLoggedIn()) {
		greetingMessage = `Hello ${getUser().name}`
	  } else {
		greetingMessage = "You are not logged in"
	  }
	
  return (
	<header className="">
	
<>
	</>
	  

	</header>
  )
}

