import React from "react"
import Type from "../../components/typography/Type"


const ProcessCard = (props) => {	
	
const level = props.level; 	
const title = props.title;
const url = props.url;
		
	switch (level) {
		  
		  case "level1":
		  return (
		  <div className="bg-orange/90 p-8">
		  	<div className="inline ">
			  <div>
			  <Type type="h5" nopadding="yes">{title}</Type>
			  </div>
			 </div>
		  </div>
	  );
	  case "level2":
			return (
			<div className="bg-orange/40 px-8 py-4">
				<div className="">
				<div className="w-full">
					<Type type="h6" nopadding="yes">{title}</Type>
				</div>
				</div>
			</div>
		);
		case "level3":
			  return (
			  <div className="w-full border-orange  border-2 bg-orange/10 mb-2">
				  <div className="p-4"><Type type="p6" nopadding="yes">{title}</Type></div>
				</div>
		  );
	  default:
	  return (
		  <div className="w-full border-yellow  border-2 bg-yellow/10 mb-2 ">
			<div className="p-8"><Type type="p6" nopadding="yes">{title}</Type></div>
		  </div>
	  );
	};	
};

export default ProcessCard;
